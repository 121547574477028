import { FC } from "react";

import { Box, Stack, Fade, Slide, useTheme } from "@mui/material";

import { ShapesProvider } from "./sharedComponents/BackgroundShapes/ShapesContext";
import { FormV2StepKey } from "./types/FormV2StepKey";

type FormV2LayoutProps = {
  step: FormV2StepKey;
  slots: {
    thankYou?: React.ReactNode;
    languageSelect: React.ReactNode;
    closeEmbedButton: React.ReactNode;
    selectionStep: React.ReactNode;
    paymentStep: React.ReactNode;
  };
  preloadSteps?: boolean;
  animateTransitions?: boolean;
};

export const FormV2Layout: FC<FormV2LayoutProps> = ({
  step,
  slots,
  preloadSteps = false,
  animateTransitions = true,
}) => {
  const theme = useTheme();

  return (
    <>
      {slots.thankYou && (
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            zIndex: 1000,
          }}
        >
          {slots.thankYou}
        </Box>
      )}
      <ShapesProvider>
        <Stack
          direction="column"
          sx={{
            position: "relative",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: { xs: theme.spacing(1), md: theme.spacing(3) },
              right: { xs: theme.spacing(2), md: theme.spacing(4) },
              zIndex: 100,
            }}
          >
            <Stack direction="row" gap={2}>
              {slots.languageSelect}
              {slots.closeEmbedButton}
            </Stack>
          </Box>

          {/* Step 1 */}
          <Fade
            appear={animateTransitions}
            in={step === "selection"}
            timeout={500}
            style={{ display: step === "selection" ? "flex" : "none", flexDirection: "column" }}
          >
            <Box sx={{ height: "100%", flexGrow: 1 }}>{step === "selection" && slots.selectionStep}</Box>
          </Fade>

          {/* Step 2 */}
          <Box width="100%" minHeight="100%" sx={{ overflowX: "hidden" }}>
            <Slide
              appear={animateTransitions}
              direction="left"
              in={step === "payment"}
              mountOnEnter={!preloadSteps} // If not preview, we preload Step 2 for Smooth Transitions
              unmountOnExit={false}
              timeout={500}
            >
              <Box
                sx={{
                  display: step === "payment" ? "block" : "none", // Ensures it's hidden when not active
                }}
              >
                {slots.paymentStep}
              </Box>
            </Slide>
          </Box>
        </Stack>
      </ShapesProvider>
    </>
  );
};
